import React, { Component, useEffect }  from 'react';
import {Legal_moves, pieceScore} from '../model/moves'

import {readBoard, readReturnBoard, boardToDict} from '../network/Board_IO'
import {inference} from '../assembly/wasm_io'
import {GameGraphicInfo} from '../graphics/game'
import { playPieceMoveSound, playPieceCaptureSound } from './sounds';


// Test board
export function StartBoard(){
    var testboard = readBoard()
  
    return(
    <div>
        {testboard.map((i,key) => {
            return <div key={key}>{i}</div>;
        })}
    </div>
    );
}

// Highest level controller of game
export class GameLogic extends Component{

    constructor(props){
        super(props);
        this.board_dimension = props.board_dimension;
        this.state = this.getInitState();
        console.log("[GameLogic::constructor] this.state:", this.state)
        
        // Values to be passed to other components
        this.closeAllOptions = false; // value to be passed to prop 

        // Functions to be passed to other components
        this.receivePieceMove = this.receivePieceMove.bind(this); // function to be passed to clickablePiece
        this.setGameStart = this.setGameStart.bind(this) // function to be passed to indicator.popUp function
        this.setGameMode = this.setGameMode.bind(this) // function to be passed to indicator.popUp function
        this.reset = this.reset.bind(this) // function to be passed to indicatator components that needs reset
        this.swapPosition = this.swapPosition.bind(this)
        this.setSide = this.setSide.bind(this)

        // bundle of functions above to pass to children
        this.GameLogicFunctions = {
            receivePieceMove : this.receivePieceMove, // function to be passed to clickablePiece
            setGameStart : this.setGameStart, // function to be passed to indicator.popUp function
            setGameMode : this.setGameMode, // function to be passed to indicator.popUp function
            reset : this.reset,// function to be passed to indicatator components that needs reset
            swapPosition : this.swapPosition,
            setSide : this.setSide
        }

    }

    // Intial board configuration
    getInitState(){
        console.log("[gameLogic::getInitState] entered")
        var board = readBoard(); // get default board
        board = randomFormation(board); // randomly choose formation
        console.log("[gameLogic::getInitState] init_board", board)
        var initState = this.readGame(board, false) // set initial state from given board

        initState.turn = true // turn coordinator. "true = HAN", "false = CHO"
        initState.player_type = randomBool() // randomly choose player's side
        initState.ply = 1
        initState.didGameStart = false
        initState.gameModeInfo = {gameMode:0} // INIT gamemode. 0 = undecided
        initState.loading = false
        initState.prevMove = null

        return initState
    }


    getCurrentState(){
        return this.state;
    }


    /*
    ** Run this whenever there is a change in board **

    // Read this.state and determines if check(jang), game over or not
    */
    
    readGame(board, autoSetState){

        var row
        var han_jang = false // whether if jang still exists or not. Is not state of "check"
        var cho_jang = false
        var cho_pieces = [] // list of index for cho pieces
        var han_pieces = [] // list of index for cho pieces
        var cho_pieces_type = [] // list of str indicating cho pieces
        var han_pieces_type = [] // list of str indicating han pieces
        var gameover = false
        var winner
        var han_num_moves = 0 // if num moves == 0 in the end, game over
        var cho_num_moves = 0

        // // represents pieces in board. can be none, or have a clickablePiece
        var board_complete = []; // holds information about all pieces and their available moves. Must be passed to graphic renderer
        var row = [];
        var piece_type;
        var moves = [];
    
        // set check state to false before checking board check state later
        var han_check = false
        var cho_check = false

        // lost pieces
        var han_lost_pieces;
        var cho_lost_pieces;

        var han_score = 0
        var cho_score = 1.5 // dummy score

        var han; // indicator whether piece is upper or not
        for (let j = 0; j < 10; j++) {
            for (let i = 0; i < 9; i++){
                piece_type = board[j][i]
    
                var move_info = []
                if (piece_type !== '.'){
                    if (isUpperCase(piece_type)){
                        han = true
                        if (piece_type === 'J'){
                            han_jang = true
                        }
                        han_score += pieceScore(piece_type) // update score
                        han_pieces.push([j, i])
                        han_pieces_type.push(piece_type)
                    } else {
                        han = false
                        if (piece_type === 'j'){
                            cho_jang = true
                        }
                        cho_score += pieceScore(piece_type) // update score
                        cho_pieces.push([j, i])
                        cho_pieces_type.push(piece_type)
                    }

                    moves = Legal_moves([i, j], board)

                    // add possible_moves
                    for (let k = 0; k < moves.length; k++){
                        var move_pos = moves[k]
                        var curr_move = {
                            move_pos: move_pos
                        }
                        var board_after_move = this.pieceMove(board, [i,j], move_pos);
                        //console.log("[GameLogic::readGame] board_before_move", board)
                        //console.log("[GameLogic::readGame] board_after_move", board_after_move)
                        
                        //check if move ends game
                        // if move of Han incurs check of cho, cho check
                        if (this.isCheck(move_pos, board)){
                            if (han){
                                cho_check = true
                            } else{
                                han_check = true
                            }

                            move_info.push(curr_move)
                            if (han){
                                han_num_moves += 1
                            } else{
                                cho_num_moves += 1
                            }
                        } else if (this.isMoveLegalByGame(isUpperCase(piece_type), board_after_move)){
                            move_info.push(curr_move)
                            if (han){
                                han_num_moves += 1
                            } else{
                                cho_num_moves += 1
                            }
                        }

                    }
                }
                // console.log('[GameLogic::readGame] pushed', [i,j], piece_type, move_info)
                row.push({
                    pos: [i, j],
                    piece_type : piece_type,
                    move_info : move_info
                })
            }
            board_complete.push(row)
            row = []
        }
        
        // If game is over
        if (han_num_moves == 0){
            gameover = true
            winner = "Cho"
        } else if(cho_num_moves == 0){
            gameover = true
            winner = "Han"
        }

        // Count lost pieces
        [han_lost_pieces, cho_lost_pieces] = this.lostPieces(han_pieces_type, cho_pieces_type)

        var finalState = {board: board, board_complete: board_complete, 
            han_pieces: han_pieces, cho_pieces: cho_pieces, han_check: han_check, cho_check: cho_check, 
            han_score: han_score, cho_score: cho_score, gameover: gameover, winner:winner,
            han_lost_pieces:han_lost_pieces, cho_lost_pieces:cho_lost_pieces, loading:false}
        
        if (autoSetState){
            this.setState(finalState)
        } else{
            return finalState
        }
    }

    // already check that the move is a valid move in previous state.
    // only have to check if piece_attacked is Jang or Not.
    // After each ply, turn han_check and cho_check to false.
    isCheck(move, board){
        var x = move[0]
        var y = move[1]

        var piece_attacked = board[y][x]

        if (piece_attacked === 'J' || piece_attacked === 'j'){
            return true
        }
        else{
            return false
        }
    }


    /* Illegal moves are: If I make a move, I get myself checked */
    isMoveLegalByGame(player, board){
        var moves = [];
        var piece_type;
    
        // Check opponent's move
        for (let j = 0; j < 10; j++) {
            for (let i = 0; i < 9; i++){
                piece_type = board[j][i]
    
                if (piece_type === '.'){
                    continue
                }
                if (player !== isUpperCase(piece_type)){
                    
                    // add possible_moves
                    moves = Legal_moves([i, j], board)
                    for (let k = 0; k < moves.length; k++){
                        var move_pos = moves[k]
                        //check if move occurs check
                        if (this.isCheck(move_pos, board)){
                            return false
                        }
                    }
                }

            }
        }
        return true
    }

    // calculate lost pieces
    // don't count 'J' and "j" because it's a lost game
    lostPieces(han_pieces, cho_pieces){
        var han_lost_pieces = {"C":2, "M":2, "S":2, "T":2, "P":2, "Z":5}
        var cho_lost_pieces = {"c":2, "m":2, "s":2, "t":2, "p":2, "z":5}
        var curr_piece

        for (let i=0; i < han_pieces.length; i++){
            curr_piece = han_pieces[i]
            han_lost_pieces[curr_piece] -= 1
        }

        for (let i=0; i < cho_pieces.length; i++){
            curr_piece = cho_pieces[i]
            cho_lost_pieces[curr_piece] -= 1
        }

        return ([han_lost_pieces, cho_lost_pieces])
    }

    /** Game progress controller. Called by receivePieceMove, setGameStart, or setGameMode. 
     * whenever someone makes a move, coordinate progress through this **/
    async coordinateGame(){
        console.log("[gameLogic::coordinateGame] entered")
        // 1. Check if game started
        if (!this.state.didGameStart){
            console.log("[gameLogic::coordinateGame] game has not started yet", this.state.didGameStart);
            return;
        }

        if (this.state.gameover){
            console.log("[gameLogic::coordinateGame] game over", this.state.gameover);
            return;
        }

        console.log("[gameLogic::coordinateGame] game mode", this.state.gameModeInfo.gameMode);
        // 2. If AI turn, wait
        if (this.state.gameModeInfo.gameMode === 1){
            console.log("[gameLogic::coordinateGame] AI mode")
            if (this.state.turn == this.state.gameModeInfo.AI_type){
                // run AI
                // This is AI's turn. run AI

                console.log("[gameLogic::coordinateGame] Preparing input for AI")
                var input_board = boardToDict(this.state.board, this.state.player_type)
                var serialized_input = JSON.stringify(input_board)

                
                console.log("[gameLogic::coordinateGame] AI turn, starting AI")
                /*
                var ret_info;
                this.setState({loading:true}, async () =>{
                    ret_info = await inference(serialized_input);
                })
                */
                const ret_info = await inference(serialized_input)

                /*.then(result => 
                    console.log(["[gameLogic::coordinateGame] promise resolved"], result ));
                */
                console.log("[gameLogic::coordinateGame] Ran AI.", this.state.gameModeInfo)
                console.log("[gameLogic::coordinateGame] Raw info returned by AI: ", ret_info)

                var AI_board = readReturnBoard(ret_info);
                
                
                // For now set Board's state into received state.
                // Change it to inference returning a move later

                // assign one by one manually to avoid multliple setState being queued up uncontrollably
                // and ensure callback function is executed.
                var ns = this.readGame(AI_board, false);
                var nt = this.nextTurn(false);
                this.setState({ 
                    board: ns.board, board_complete: ns.board_complete, 
                    han_pieces: ns.han_pieces, cho_pieces: ns.cho_pieces, 
                    han_check: ns.han_check, cho_check: ns.cho_check, 
                    han_score: ns.han_score, cho_score: ns.cho_score, 
                    gameover: ns.gameover, winner: ns.winner,
                    han_lost_pieces:ns.han_lost_pieces, cho_lost_pieces:ns.cho_lost_pieces,
                    turn: nt.turn, ply: nt.ply, loading: nt.loading
                })
                
                return;
            }
            console.log("[gameLogic::coordinateGame] AI mode but not AI's turn")
            console.log("[gameLogic::coordinateGame] this.turn: ", this.turn, "AI_type", this.state.gameModeInfo.AI_type)
        }

        console.log("[gameLogic::coordinateGame] user turn");
    }

    /**  Function to be passed to Prop of clickablePieces, for moving pieces **/
    receivePieceMove(from_pos, to_pos){
        console.log('from', from_pos, 'to', to_pos)
        var curr_piece = this.state.board[from_pos[1]][from_pos[0]]
        var dest_piece = this.state.board[to_pos[1]][to_pos[0]]

        if (dest_piece === '.'){
            playPieceMoveSound();
        } else {
            playPieceCaptureSound();
        }

        var new_board = this.state.board
        new_board[to_pos[1]][to_pos[0]] = curr_piece
        new_board[from_pos[1]][from_pos[0]] = '.'

        //this.readGame(new_board)
        //this.nextTurn()
        //console.log("here")

        var ns = this.readGame(new_board);
        var nt = this.nextTurn(false);
        this.setState({ 
            board: ns.board, board_complete: ns.board_complete, 
            han_pieces: ns.han_pieces, cho_pieces: ns.cho_pieces, 
            han_check: ns.han_check, cho_check: ns.cho_check, 
            han_score: ns.han_score, cho_score: ns.cho_score, 
            gameover: ns.gameover, winner: ns.winner,
            han_lost_pieces:ns.han_lost_pieces, cho_lost_pieces:ns.cho_lost_pieces,
            turn: nt.turn, ply: nt.ply
        } , () => this.coordinateGame())


        //this.coordinateGame();
    }

    /* A helper function to be called by isCheckMate */
    pieceMove(board, from_pos, to_pos){
        let new_board = copyBoard(board)

        const curr_piece = new_board[from_pos[1]][from_pos[0]]

        new_board[to_pos[1]][to_pos[0]] = curr_piece
        new_board[from_pos[1]][from_pos[0]] = '.'

        //console.log('[GameLogic::pieceMove]','curr_piece', curr_piece)
        //console.log('[GameLogic::pieceMove]','original board', board, 'from_pos', from_pos, 'to', to_pos)
        //console.log('[GameLogic::pieceMove]','new_board', new_board)
        
        return new_board
    }

    setGameStart(){
        console.log("[gameLogic::setGameStart] entered")
        console.log("[gameLogic::setGameStart] gameStart before: ", this.state.didGameStart)
       
        this.setState( {didGameStart:true}, 
            () => this.coordinateGame() );
    }

    setGameMode(modeNumber){
        /*
            1 = user vs Com
            2 = user vs user (offline, same device)
            3 = user vs user (online)
        */
        
        console.log("[gameLogic::setGameMode] game mode entered ")
        var gameModeInfo = {gameMode:modeNumber}
        if (modeNumber === 1){
            console.log("[gameLogic::setGameMode] AI mode selected ")
            gameModeInfo.AI_type = !this.state.player_type
        }
        
        this.setState({gameModeInfo:gameModeInfo, didGameStart:true}, () => {
            console.log("[gameLogic::setGameMode] game mode set: ", this.state.gameModeInfo.gameMode, 
                " AI type: ", this.state.gameModeInfo.AI_type ), 
            this.coordinateGame()
        })
        
        
    }


    /*
    Button functionalities
    */
 
    swapPosition(first, second){
        var board = this.state.board
        var first_piece = board[first[1]][first[0]]
        var second_piece = board[second[1]][second[0]]

        board[first[1]][first[0]] = second_piece
        board[second[1]][second[0]] = first_piece
        this.readGame(board, true)
    }

    

    setSide(side){
        this.setState({turn: side})
    }

    reset(){
        this.setState(this.getInitState())
    }

    nextTurn(autoSetState){
        if (autoSetState){
            this.setState({turn: !this.state.turn, ply: this.state.ply + 1 } , 
                () => console.log("[GameLogic::nextTurn] next turn: ", this.state.turn, "ply: ", this.state.ply));
        }
        return ({turn: !this.state.turn, ply: this.state.ply + 1 })
    }

    render(){
        return(
            <React.Fragment>
                <GameGraphicInfo board_dimension={this.board_dimension} board_complete={this.state.board_complete}
                gameFunctions={this.GameLogicFunctions} gameState={this.state} start={this.setGameStart}
                />
            </React.Fragment>
        )
    }

    /* Effectively a useEffect for class components */
    componentDidUpdate(prevProps, prevState) {
        if (this.state.gameModeInfo.gameMode === 1){
            if (this.state.turn !== prevState.turn){
                if (this.state.turn !== this.state.gameModeInfo.AI_type){
                    console.log("preveState",prevState)
                    console.log("currentState",this.state)
                    playPieceMoveSound();
                }
            }
            
        }
    }


}

const isUpperCase = (string) => /^[A-Z]*$/.test(string)

function copyBoard(board){
    let new_board = JSON.parse(JSON.stringify(board));
    return new_board
}

function randomBool(){
    return (Math.random() < 0.5)
}

// receives initial board state and randomly assigns initial formation
function randomFormation(board){
    // han random config
    if (randomBool()){
        board = swapFormation(board, [1,0], [2,0])
    }
    if (randomBool()){
        board = swapFormation(board, [6,0], [7,0])
    }

    // cho random config
    if (randomBool()){
        board = swapFormation(board, [1,9], [2,9])
    }
    if (randomBool()){
        board = swapFormation(board, [6,9], [7,9])
    }
    return board;
}

function swapFormation(board, first, second){
    var first_piece = board[first[1]][first[0]]
    var second_piece = board[second[1]][second[0]]

    board[first[1]][first[0]] = second_piece
    board[second[1]][second[0]] = first_piece

    return board;
}

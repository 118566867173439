import piece_move_sound from '../assets-new/sounds/piece_move.wav';
import piece_capture_sound from '../assets-new/sounds/piece_capture.mp3';

/* load all audios here */
export const pieceMoveSound = new Audio(piece_move_sound);
export const pieceCaptureSound = new Audio(piece_capture_sound);

export const playPieceMoveSound = async () => {
    console.log("play piece move sound:: start")
    pieceMoveSound.play()
     .then(() => {
        console.log("play piece move sound:: Sound finished playing");
      })
  };

export const playPieceCaptureSound = async () => {
    console.log("play piece capture sound:: start")
    pieceCaptureSound.play()
     .then(() => {
        console.log("play piece capture sound:: Sound finished playing");
      })
  }
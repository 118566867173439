import React from 'react'
import './panels.css'
import GetPieceImagePath from './pieceImage'
import { APP_RED, APP_BLUE } from './pallete'
import { ReactComponent as SWAP_CHO } from '../assets-new/Swap_Cho.svg'
import { ReactComponent as SWAP_HAN } from '../assets-new/Swap_Han.svg'

// A panel Indicating turn
// True == Han, False == Cho
export const userLabel = (state) => {
    var turn = state.turn
    var ply = state.ply
    var gameover = state.gameover
    var han_check;
    var cho_check;
    var gameMode;
    var playerType;
    var playerTypeColor;
    var lastAIMove;

    var text;
    var textColor;
    var isOver;

    if (state.player_type) {
        playerType = "Han"
        playerTypeColor = 'red'
    } else {
        playerType = "Cho"
        playerTypeColor = 'blue'
    }

    if (turn) {
        text = "Han"
        textColor = 'red'
    } else {
        text = "Cho"
        textColor = 'blue'
    }

    if (gameover) {
        isOver = 'true'
    } else {
        isOver = 'false'
    }

    if (state.han_check) {
        han_check = "check"
    } else {
        han_check = "false"
    }

    if (state.cho_check) {
        cho_check = "check"
    } else {
        cho_check = 'false'
    }

    if (state.gameModeInfo.gameMode === 1) {
        gameMode = "User vs Com"
    } else if (state.gameModeInfo.gameMode === 2) {
        gameMode = "User vs User (Offline)"
    } else if (state.gameModeInfo.gameMode === 3) {
        gameMode = "User vs User (Online)"
    } else {
        gameMode = "Not Set"
    }

    // change to actual WASM result.
    lastAIMove = "TEST MOVE Within indicator.js";

    return (
        <div>
            <div> game mode: {gameMode}</div>
            <div style={{ color: playerTypeColor }}> my color: {playerType}</div>
            <div style={{ color: textColor }}>turn: {text}</div>
            <div> han score:{state.han_score}</div>
            <div> cho score:{state.cho_score}</div>
            <div> ply:{ply}</div>
            <div> AI move:{lastAIMove}</div>
            <br></br>
            {lostPiecesLabel(state.han_lost_pieces)}
            {lostPiecesLabel(state.cho_lost_pieces)}
        </div>
    )
};


// A panel Indicating turn
// True == Han, False == Cho
export const turnLabel = (state) => {
    var turn = state.turn
    var open_id = state.open_id
    var ply = state.ply
    var didGameStart;
    var gameover = state.gameover
    var han_check;
    var cho_check;

    var text;
    var textColor;
    var isOver;

    if (turn) {
        text = "Han"
        textColor = 'red'
    } else {
        text = "Cho"
        textColor = 'blue'
    }

    if (gameover) {
        isOver = 'true'
    } else {
        isOver = 'false'
    }

    if (state.han_check) {
        han_check = "check"
    } else {
        han_check = "false"
    }

    if (state.cho_check) {
        cho_check = "check"
    } else {
        cho_check = 'false'
    }

    if (state.didGameStart) {
        didGameStart = "true"
    } else {
        didGameStart = 'false'
    }

    return (
        <div>
            <div style={{ color: textColor }}>turn: {text}</div>
            <div> han_score:{state.han_score}</div>
            <div> cho_score:{state.cho_score}</div>
            <div> open_id:{open_id}</div>
            <div> ply:{ply}</div>
            <div> han_check:{han_check} </div>
            <div> cho_check:{cho_check} </div>
            <div> gameover:{isOver}</div>
            <div> gameStart:{didGameStart}</div>
            <br></br>
            {textBoard(state.board)}
        </div>
    )
};

export const textBoard = (board) => {
    return (
        <div>
            {board.map((row) => {
                return (
                    <div>
                        {row}
                    </div>
                )
            })}
        </div>
    )
};

function swapButton(top, left, color, swap_1, swap_2, swapPosition, reverse = false) {
    var margin = 16

    if (!reverse) {
        return (
                <SWAP_HAN
                    className= "swapImage"
                    style={{ 
                        position: 'absolute', // Crucial for using top/left
                        top: `${top - 10}px`, 
                        left: `${left + margin}px`,
                    }}
                    onClick={() => swapPosition(swap_1, swap_2)}
                />
        )
    }

    return (
            <SWAP_CHO
                className= "swapImage"
                style={{ 
                    position: 'absolute',
                    top: `${top+6}px`,
                    left: `${left + margin}px`,
                }}
                onClick={() => swapPosition(swap_1, swap_2)}
            />
    )
}

function swapButton2(top, left, color, swap_1, swap_2, swapPosition, reverse = false) {
    const coneGapWidth = 79;

    var left_cone = 8.5;
    var right_cone = left_cone + coneGapWidth;
    
    var left_cone_pos = left_cone.toString() + 'px'
    var right_cone_pos = right_cone.toString() + 'px'

    console.log("cone gap", right_cone - left_cone)
    console.log("coneGapWidth", coneGapWidth)

    var margin = 20
    var width = coneGapWidth + 2 * margin

    
    if (!reverse) {
        return (
            <div className="SwapPanel" style={{ color: color, top: top, left: left - margin, width: width, zIndex: 1 }} onClick={() => swapPosition(swap_1, swap_2)}>
                <div className='bottom-bar' style={{ top:'25px', width: width }}></div>
                <div className="ConicsContainer" style={{ width: width }}>
                    <div className='top-conic' style={{ top: '0px', left: left_cone_pos, borderColor: 'yellow', borderWidth: 2, borderStyle: 'solid' }} ></div>
                    <div className='top-conic' style={{ top: '0px', left: right_cone_pos, borderColor: 'yellow', borderWidth: 2, borderStyle: 'solid' }} ></div>
                </div>
                <span style={{ position: 'absolute', top: 0 }}>swap</span>
            </div>
        )
    }

    return (
        <div className="SwapPanel" style={{ color: color, top: top, left: left - margin, width: width, zIndex: 1 }} onClick={() => swapPosition(swap_1, swap_2)}>
            <span style={{ position: 'absolute', bottom: 5 }}>swap</span>
            <div className='bottom-bar' style={{ bottom:'25px', width: width }}></div>
            <div className="ConicsContainer" style={{ width: width}}>
                <div className='top-conic' style={{ bottom: '0px', left: left_cone_pos, borderColor: 'yellow', borderWidth: 2, borderStyle: 'solid', transform: 'rotate(180deg)' }} ></div>
                <div className='top-conic' style={{ bottom: '0px', left: right_cone_pos, borderColor: 'yellow', borderWidth: 2, borderStyle: 'solid', transform: 'rotate(180deg)' }} ></div>
            </div>
        </div>
    )
}

// swap button
export function hanSwap(grid, swapPosition) {
    var left_1 = [1, 0]
    var left_2 = [2, 0]
    var right_1 = [6, 0]
    var right_2 = [7, 0]

    var loc_y = grid[left_2[1]][left_2[0]][1] + 30
    var loc_1_x = (grid[left_1[1]][left_1[0]][0])
    var loc_2_x = (grid[right_1[1]][right_1[0]][0])

    return (
        <div>
            {swapButton(loc_y, loc_1_x, APP_RED, left_1, left_2, swapPosition)}
            {swapButton(loc_y, loc_2_x, APP_RED, right_1, right_2, swapPosition)}
        </div>
    );
};

export function choSwap(grid, swapPosition) {
    var left_1 = [1, 9]
    var left_2 = [2, 9]
    var right_1 = [6, 9]
    var right_2 = [7, 9]

    var loc_y = grid[left_2[1]][left_2[0]][1] - 40 - 34
    var loc_1_x = (grid[left_1[1]][left_1[0]][0])
    var loc_2_x = (grid[right_1[1]][right_1[0]][0])

    return (
        <div>
            {swapButton(loc_y, loc_1_x, APP_BLUE, left_1, left_2, swapPosition, true)}
            {swapButton(loc_y, loc_2_x, APP_BLUE, right_1, right_2, swapPosition, true)}
        </div>
    );
};

const formation = (setformation) => {
    return (
        <div style={{}}>
            {startPiece('m')}
            {startPiece('s')}
            {startPiece('s')}
            {startPiece('m')}
        </div>
    );
};

const startPiece = (piece) => {
    return (
        <img src={GetPieceImagePath(piece)} style={{ position: 'relative', height: 100, opacity: 1 }}>
        </img>
    )
}

export function gameStartOption(didGameStart, setGameStart, setGameMode, playingSide) {
    console.log("[indicator.js::gameStartOption()] entered. gameStart: ", didGameStart)
    var textColor
    var side
    if (playingSide) {
        side = "Han"
        textColor = "red"
    } else {
        side = "Cho"
        textColor = "blue"
    }

    const USER_VS_COM = 1
    const USER_VS_USER_OFFLINE = 2
    const USER_VS_USER_ONLINE = 3

    return (!didGameStart) ? (
        <div className="Box" style={{ position: 'absolute', top: '25%', left: '10%', width: '80%', height: '50%' }}>

            <div className="StartInfoText" style={{ position: 'absolute', width: '100%', height: "40%" }}>
                {"You are playing "}
                <span style={{ color: textColor, fontWeight: 'bold' }}>{side} </span>
                <br></br>
                Select Your Formation
            </div>

            <div className="StartButton" style={{
                position: 'absolute', top: '40%', width: '50%', height: "60%",
                zIndex: 1
            }} onClick={() => { setGameMode(USER_VS_COM) }}>
                <div className="StartButtonText" >
                    <div>
                        AI MODE
                    </div>
                </div>
            </div>

            <div className="StartButton" style={{
                position: 'absolute', top: '40%', left: '50%', width: '50%', height: "60%",
                background: "green", zIndex: 1
            }} onClick={() => { setGameMode(USER_VS_USER_OFFLINE); }}>
                <div className="StartButtonText" >
                    <div>
                        USER VS USER (OFFLINE)
                    </div>
                </div>
            </div>

        </div>
    ) : null;
};

export function playingSideIndicator(didGameStart, playingSide) {
    var textColor
    var side
    if (playingSide) {
        side = "Han"
        textColor = APP_RED
    } else {
        side = "Cho"
        textColor = APP_BLUE
    }

    return (!didGameStart) ? (
        <div id="playingSideInddicator"
            style={{ width: '100%', height: "10%", justifyContent: 'center' }}>
            {"You are playing "}
            <span style={{ color: textColor, fontWeight: 'bold' }}>{side} </span>
            <br></br>
            Select Your Formation
        </div>
    ) : null;
}

export function gameModeSelector(setGameMode) {
    const USER_VS_COM = 1
    const USER_VS_USER_OFFLINE = 2
    const USER_VS_USER_ONLINE = 3

    return (
        <div id="gameModeSelector" style={{
            margin: '0 auto', justifyContent: 'center',
            display: 'flex', flexDirection: 'column',
            width: '90%', height: "20%", alignItems: 'center'
        }}>
            <div style={{ height: '30px' }}></div>

            <div className="GameModeOptionButton"
                style={{ zIndex: 1 }}
                onClick={() => { setGameMode(USER_VS_COM) }}>
                <div>
                    AI Mode
                </div>
            </div>

            <div style={{ height: '20px' }}></div>

            <div className="GameModeOptionButton"
                style={{ zIndex: 1 }}
                onClick={() => { setGameMode(USER_VS_USER_OFFLINE) }}>
                <div>
                    USER VS USER
                    <br></br>
                    (OFFLINE)
                </div>
            </div>

            <div style={{ height: '30px' }}></div>
        </div>
    )
}


export function gameStartOption2(didGameStart, setGameStart, setGameMode, playingSide) {
    console.log("[indicator.js::gameStartOption()] entered. gameStart: ", didGameStart)
    var textColor
    var side
    if (playingSide) {
        side = "Han"
        textColor = "red"
    } else {
        side = "Cho"
        textColor = "blue"
    }

    const USER_VS_COM = 1
    const USER_VS_USER_OFFLINE = 2
    const USER_VS_USER_ONLINE = 3

    return (!didGameStart) ? (
        <div className="Box" style={{ position: 'absolute', top: '25%', left: '10%', width: '80%', height: '50%' }}>

            <div className="StartInfoText" style={{ position: 'absolute', width: '100%', height: "40%" }}>
                {"You are playing "}
                <span style={{ color: textColor, fontWeight: 'bold' }}>{side} </span>
                <br></br>
                Select Your Formation
            </div>

            <div className="StartButton" style={{
                position: 'absolute', top: '40%', width: '50%', height: "60%",
                zIndex: 1
            }} onClick={() => { setGameMode(USER_VS_COM) }}>
                <div className="StartButtonText" >
                    <div>
                        AI MODE
                    </div>
                </div>
            </div>

            <div className="StartButton" style={{
                position: 'absolute', top: '40%', left: '50%', width: '50%', height: "60%",
                background: "green", zIndex: 1
            }} onClick={() => { setGameMode(USER_VS_USER_OFFLINE); }}>
                <div className="StartButtonText" >
                    <div>
                        USER VS USER (OFFLINE)
                    </div>
                </div>
            </div>

        </div>
    ) : null;
};

export function gameOver(gameover, winner, reset, setGameMode) {
    var textColor
    if (winner === "Han") {
        textColor = "red"
    } else if (winner === "Cho") {
        textColor = "blue"
    }

    const USER_VS_COM = 1
    const USER_VS_USER_OFFLINE = 2
    const USER_VS_USER_ONLINE = 3

    return (gameover) ? (
        <div className="Box" style={{ position: 'absolute', top: '25%', left: '10%', width: '80%', height: '50%' }}>
            <div style={{ fontSize: "200%", position: 'absolute', width: '100%', height: "40%" }} >
                {"Game Over"}
                <br></br>
                <span style={{ color: textColor, fontWeight: 'bold' }}>{winner} </span> Wins
            </div>

            <div className="ReplayBox" style={{
                position: 'absolute', top: '40%', width: '100%', height: "60%",
                zIndex: 1
            }} onClick={() => { reset() }}>
                <div className="ReplayText" >
                    <div>
                        REPLAY
                    </div>
                </div>
            </div>

        </div>
    ) : null;
};

// draw lost pieces.
// Iterate manually to maintain order.
function lostPiecesLabel(lostPieces) {
    const han_piece_list = ["C", "M", "S", "T", "P", "Z"]
    const cho_piece_list = ["c", "m", "s", "t", "p", "z"]
    var show_list = [];
    var p; //current piece

    // han pieces
    for (let j = 0; j < han_piece_list.length; j++) {
        p = han_piece_list[j]
        for (let i = 0; i < lostPieces[p]; i++) {
            show_list.push(p)
        }
    }

    // cho pieces
    for (let j = 0; j < cho_piece_list.length; j++) {
        p = cho_piece_list[j]
        for (let i = 0; i < lostPieces[p]; i++) {
            show_list.push(p)
        }
    }

    return (
        <div className="LostPieces">
            {show_list.map((p) => {
                return (
                    <img src={GetPieceImagePath(p)} width="30">
                    </img>
                )
            })}
        </div>
    )
};
import React, { Component }  from 'react';
import './panels.css'
import {getGrid} from './gameboard'
import GetPieceImagePath from './pieceImage';
import refreshIcon from '../assets-new/refresh-icon.png'

import {GameLogic} from '../model/janggi'
import {readBoard, readReturnBoard} from '../network/Board_IO'
import {turnLabel, userLabel, textBoard, gameStartOption, gameOver, hanSwap, choSwap, playingSideIndicator, gameModeSelector} from './indicator'
import {ClickablePiece} from './piece'


// read board from model, and draws entire gameset
// assembles entire graphic component and is the final graphic renderer

export function StartBoard(){
    var testboard = readBoard()
  
    return(
    <div>
        {testboard.map((i,key) => {
            return <div key={key}>{i}</div>;
        })}
    </div>
    );
}

// ** Essentially works as main model **
export function DrawGame(w, h){
    return(
        <React.Fragment>
            <GameGraphicInfo w={w} h={h}/>
        </React.Fragment>
    )
}

export class GameGraphicInfo extends Component{

    constructor(props){
        super(props)
        this.gameFunctions = props.gameFunctions
        this.board_complete = props.board_complete;
        this.grid = getGrid(props.board_dimension.w, props.board_dimension.h);

        // Values to be passed to other components
        this.closeAllOptions = false; // value to be passed to prop
        
        // States
        this.state = {open_id:99};

        this.optionsOpened = this.optionsOpened.bind(this)


    }

    componentDidUpdate(prevProps) {
        if (this.props.board_dimension !== prevProps.board_dimension){
            this.grid = getGrid(this.props.board_dimension.w, this.props.board_dimension.h)
            console.log("board dimension changed.")
        }
    }

    // {this.DrawPieces()}
    // {this.DrawStartOption()}
    // {this.DrawGameOverButton()}
    // {this.DrawHanSwap()}
    // {this.DrawChoSwap()}


    // Function to be passed to Prop of clickablePieces
    optionsOpened(updated_id){
        console.log("[GameGraphicInfo::optionsOpened]open_id before: ", this.state.open_id)
        var pre_open_id = this.state.open_id
        this.setState({open_id : updated_id} , () => console.log("[GameGraphicInfo::optionsOpened] setState to open_id", this.state.open_id) )
        //this.state.open_id = updated_id
        console.log('[GameGraphicInfo::optionsOpened] open_id changed from', pre_open_id, 'to', this.state.open_id)
    }

    DrawPieces(){
        var grid = this.grid; // represents coordinates that images will be drawn, given position
        var board_complete = this.props.gameState.board_complete;

        var coord_pixel;
        var piece_type;
        var grid_w = grid[1][1][0] - grid[0][0][0]
        var grid_h = grid[1][1][1] - grid[0][0][1]
    
        const origin_w = 119 // Pixel size of original image
        const origin_h = 105 // Pixel size of original image
    
        // control image size by controlling height
        var size_ratio = 1 
        //(grid_w * 1.4) / origin_w
    
        var image_w = grid_w * size_ratio
        var image_h = grid_h * size_ratio

        var point_info;
        var id_cnt = 0;
        var move_info;
        var move;
        for (let j = 0; j < 10; j++) {
            for (let i = 0; i < 9; i++){
                coord_pixel = grid[j][i]
                point_info = board_complete[j][i]
                piece_type = point_info.piece_type
                move_info = point_info.move_info

                var id = false // id = false if point is empty of piece. replace with more robust method later
                if (piece_type !== '.'){
                    // add possible_moves
                    for (let k = 0; k < move_info.length; k++){
                        move = move_info[k]
                        var move_pos = move.move_pos
                        move.x_pixel = grid[move_pos[1]][move_pos[0]][0] - image_w / 2
                        move.y_pixel = grid[move_pos[1]][move_pos[0]][1] - image_h / 2
                    }
                    id = id_cnt // update id to unique number for each piece. replace with more robust method later
                    id_cnt = id_cnt + 1
                }

                /*
                original point_info
                pos: [i, j],
                piece_type : piece_type,
                move_info : move_info [{}, {},]
                */

                point_info.x_pixel = coord_pixel[0]
                point_info.y_pixel = coord_pixel[1]
                point_info.image_w = image_w
                point_info.image_h = image_h
                point_info.image_path = GetPieceImagePath(piece_type)
                point_info.id = id
            }
          }
        return(
            <React.Fragment>
            {board_complete.map((row) => {
                return(
                    row.map((point_info) => {
                        if (point_info.piece_type !== '.'){
                            return(
                                // use uid to get unique id for each piece. Right now its rendering and creating new object everytime
                                // a board state change, but pieces will be independent components in future.
                                <ClickablePiece point_info={point_info} optionsOpened={this.optionsOpened} open_id={this.state.open_id} 
                                turn={this.props.gameState.turn} didGameStart = {this.props.gameState.didGameStart} gameover={this.props.gameState.gameover} 
                                receivePieceMove={this.props.gameFunctions.receivePieceMove} 
                                key={point_info.id.toString()} gameModeInfo={this.props.gameState.gameModeInfo} />
                            )
                        }
                    })
                )
            })}
            </React.Fragment>
        )

        
    }

    /*
    Buttons and panels. Some are imported from other scripts, some are created here.
    Make things coherent later
    */

    DrawUserPanel(){
        return(
            <div className="UserPanel" >
                <div style={{ height: '50px' }}></div>
                {playingSideIndicator(this.props.gameState.didGameStart, this.props.gameState.player_type)}
                <div style={{ height: '50px' }}></div>
                {gameModeSelector(this.props.gameFunctions.setGameMode)}
                <div style={{ height: '50px' }}></div>
                {this.DrawResetButton()}
                <br></br>
                
            </div>
        )
    }
    //{userLabel(this.gameLogic.state)}

    DrawTestPanel(){
        return(
            <div className="TestPanel" >
                {this.DrawResetButton()}
                {this.DrawChooseSideButton()}
                /*<br></br>
                {turnLabel(this.props.gameState)}
            </div>
        )
    }
    //{turnLabel(this.gameLogic.state)}

    DrawHanSwap(){
        return (!this.props.gameState.didGameStart) ? (
            console.log("hanSwap"),
            hanSwap(this.grid, this.props.gameFunctions.swapPosition)
        ):null;
    }
    DrawChoSwap(){
        return (!this.props.gameState.didGameStart) ? (
            choSwap(this.grid, this.props.gameFunctions.swapPosition)
        ):null;
    }

    DrawStartOption(){
        return gameStartOption(this.props.gameState.didGameStart, this.props.gameFunctions.setGameStart,
            this.props.gameFunctions.setGameMode, this.props.gameState.player_type)
    }

    DrawResetButton(){
        return(
            <button style={{ borderRadius: '10px', display: 'flex', alignItems: 'center', 
            height:'50px', width:'150px', border: 'none', backgroundColor:'grey'}} 
                onClick={() => this.props.gameFunctions.reset()}>
                <img
                    src={refreshIcon}
                    alt="Pressable Image"
                    style={{ marginLeft: '10px', width: '30px', height: '30px' }} // You can adjust the width and height as per your image size.
                />
                 <p style={{ marginLeft: '5px' }}>{'Reset Board'}</p>
            </button>
        )
    }

    DrawChooseSideButton(){
        return(
            <div>
                <div style={{float:"left", backgroundColor:'white', position:'relative', 
                width:"35%", left:30, top:10, color:'red'}} onClick={() => this.props.gameFunctions.setSide(true)}>
                    Han
                </div>
                <div style={{float: "right", backgroundColor:'white', position:'relative', 
                width:"35%", right:30, top:10, color:'blue'}} onClick={() => this.props.gameFunctions.setSide(false)}>
                    Cho
                </div>
                <div style={{clear:"both"}}> </div>
            </div>
        )
    }

    DrawGameOverButton(){
        return gameOver(this.props.gameState.gameover, this.props.gameState.winner ,
            this.props.gameFunctions.reset, this.props.gameFunctions.setGameMode)
    }


    render(){
        return(
        <React.Fragment>
            {this.DrawPieces()}
            {/*this.DrawStartOption()*/}
            {this.DrawGameOverButton()}
            {/* {this.DrawTestPanel()} */}
            {this.DrawUserPanel()}
            {this.DrawHanSwap()}
            {this.DrawChoSwap()}
        </React.Fragment>
        )
    }
}



const isUpperCase = (string) => /^[A-Z]*$/.test(string)

function randomBool(){
    return (Math.random() < 0.5)
}

// receives initial board state and randomly assigns initial formation
function randomFormation(board){
    // han random config
    if (randomBool()){
        board = swapFormation(board, [1,0], [2,0])
    }
    if (randomBool()){
        board = swapFormation(board, [6,0], [7,0])
    }

    // cho random config
    if (randomBool()){
        board = swapFormation(board, [1,9], [2,9])
    }
    if (randomBool()){
        board = swapFormation(board, [6,9], [7,9])
    }
    return board;
}

function swapFormation(board, first, second){
    var first_piece = board[first[1]][first[0]]
    var second_piece = board[second[1]][second[0]]

    board[first[1]][first[0]] = second_piece
    board[second[1]][second[0]] = first_piece

    return board;
}


